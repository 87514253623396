import axios from "axios"
import Cookies from 'js-cookie'

export default defineNuxtPlugin((NuxtApp) => {
    const runtimeConfig = useRuntimeConfig();

    const BACKEND_URL = runtimeConfig.public.API_URL

    axios.defaults.baseURL = BACKEND_URL
    axios.defaults.withCredentials = false;
    axios.defaults.proxyHeaders = false;
    if(process.client){
        const token = Cookies.get('token');
        if(token){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
    }
    return {
        provide: { 
            axios: axios
        },
    }
})