<template>
    <div 
        class="fixed w-full min-w-[250px] max-w-[300px] h-14 flex items-center top-8 duration-500 rounded-md shadow z-[60] text-white overflow-hidden"
        :class="{ 
            'bg-red-600': state.type == 'error',
            'bg-green-600': state.type == 'success',
            'right-2': state.show,
            '-right-full': !state.show,
        }">
            <span 
                class="h-full w-12 flex items-center justify-center"
                :class="{ 
                    'bg-red-700': state.type == 'error',
                    'bg-green-700': state.type == 'success',
                }">
                    <Icon name="lucide:alert-octagon" size="21" />
            </span>

            <div class="p-2 flex-1 flex items-center justify-between">
                <p class="text-xs">{{ state.message }}</p>

                <button 
                    type="button" 
                    :class="{ 
                        'text-red-900': state.type == 'error',
                        'text-green-900': state.type == 'success',
                    }"
                    @click="closeAlert">
                        <Icon name="lucide:x-circle" size="21" />
                </button>
            </div>
    </div>
</template>

<script setup>

import { useAlert } from '~/stores/alert'


const { state, closeAlert } = useAlert()


</script>