import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_sVCuMR6hEC from "/app/plugins/axios.js";
import maska_r8BHBbSmHt from "/app/plugins/maska.js";
import primevue_TdXjRgL1MA from "/app/plugins/primevue.js";
import settings_2MnfoqQ5ue from "/app/plugins/settings.js";
import v_calendar_oQ1bZ1yFoH from "/app/plugins/v-calendar.js";
import vueMoney_ZBYVKc6mp0 from "/app/plugins/vueMoney.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  axios_sVCuMR6hEC,
  maska_r8BHBbSmHt,
  primevue_TdXjRgL1MA,
  settings_2MnfoqQ5ue,
  v_calendar_oQ1bZ1yFoH,
  vueMoney_ZBYVKc6mp0
]