import { default as RecoverO3Je9x7rZeMeta } from "/app/pages/Recover.vue?macro=true";
import { default as indexIdAJ0QtLRNMeta } from "/app/pages/affiliates/index.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexxaIjqR07a6Meta } from "/app/pages/pixel/index.vue?macro=true";
import { default as index8nN0KvhmxUMeta } from "/app/pages/postback/index.vue?macro=true";
import { default as indexiQaTaiwcnoMeta } from "/app/pages/transactions/index.vue?macro=true";
import { default as profileWu1o0nyKd6Meta } from "/app/pages/user/profile.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
import { default as indexOmMRdh2c84Meta } from "/app/pages/withdraw/index.vue?macro=true";
export default [
  {
    name: RecoverO3Je9x7rZeMeta?.name ?? "Recover",
    path: RecoverO3Je9x7rZeMeta?.path ?? "/Recover",
    meta: RecoverO3Je9x7rZeMeta || {},
    alias: RecoverO3Je9x7rZeMeta?.alias || [],
    redirect: RecoverO3Je9x7rZeMeta?.redirect || undefined,
    component: () => import("/app/pages/Recover.vue").then(m => m.default || m)
  },
  {
    name: indexIdAJ0QtLRNMeta?.name ?? "affiliates",
    path: indexIdAJ0QtLRNMeta?.path ?? "/affiliates",
    meta: indexIdAJ0QtLRNMeta || {},
    alias: indexIdAJ0QtLRNMeta?.alias || [],
    redirect: indexIdAJ0QtLRNMeta?.redirect || undefined,
    component: () => import("/app/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard",
    path: dashboardJu37u7k0toMeta?.path ?? "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxaIjqR07a6Meta?.name ?? "pixel",
    path: indexxaIjqR07a6Meta?.path ?? "/pixel",
    meta: indexxaIjqR07a6Meta || {},
    alias: indexxaIjqR07a6Meta?.alias || [],
    redirect: indexxaIjqR07a6Meta?.redirect || undefined,
    component: () => import("/app/pages/pixel/index.vue").then(m => m.default || m)
  },
  {
    name: index8nN0KvhmxUMeta?.name ?? "postback",
    path: index8nN0KvhmxUMeta?.path ?? "/postback",
    meta: index8nN0KvhmxUMeta || {},
    alias: index8nN0KvhmxUMeta?.alias || [],
    redirect: index8nN0KvhmxUMeta?.redirect || undefined,
    component: () => import("/app/pages/postback/index.vue").then(m => m.default || m)
  },
  {
    name: indexiQaTaiwcnoMeta?.name ?? "transactions",
    path: indexiQaTaiwcnoMeta?.path ?? "/transactions",
    meta: indexiQaTaiwcnoMeta || {},
    alias: indexiQaTaiwcnoMeta?.alias || [],
    redirect: indexiQaTaiwcnoMeta?.redirect || undefined,
    component: () => import("/app/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: profileWu1o0nyKd6Meta?.name ?? "user-profile",
    path: profileWu1o0nyKd6Meta?.path ?? "/user/profile",
    meta: profileWu1o0nyKd6Meta || {},
    alias: profileWu1o0nyKd6Meta?.alias || [],
    redirect: profileWu1o0nyKd6Meta?.redirect || undefined,
    component: () => import("/app/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: indexnq54o81oozMeta?.name ?? "users",
    path: indexnq54o81oozMeta?.path ?? "/users",
    meta: indexnq54o81oozMeta || {},
    alias: indexnq54o81oozMeta?.alias || [],
    redirect: indexnq54o81oozMeta?.redirect || undefined,
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexOmMRdh2c84Meta?.name ?? "withdraw",
    path: indexOmMRdh2c84Meta?.path ?? "/withdraw",
    meta: indexOmMRdh2c84Meta || {},
    alias: indexOmMRdh2c84Meta?.alias || [],
    redirect: indexOmMRdh2c84Meta?.redirect || undefined,
    component: () => import("/app/pages/withdraw/index.vue").then(m => m.default || m)
  }
]