<template>
  <div>
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
    <Alerts />
  </div>
</template>
<style>
body {
  overscroll-behavior: none;
}
</style>
