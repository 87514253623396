import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAlert = defineStore( 'alert', () => {
    const state = ref({
        show: false,
        type: '',
        message: '',
    })

    const showAlert = function ( type, message, timeout = 2000 ) {
        state.value.type = type
        state.value.message = message
        state.value.show = true

        setTimeout( () => state.value.show = false, timeout + 500 ) // 500 -> animation enter
    }

    const closeAlert = function () {
        state.value.show = false
    }

    return {
        state, showAlert, closeAlert,
    }
} )
